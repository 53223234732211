.favouriteContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;

  .favouriteHeader {
    padding: 3rem;
    font-size: 3rem;
  }

  .favouriteContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;

    .favouriteContentText {
      font-size: 2rem;
      margin-top: 2rem;
    }
  }

  .favouriteFooter {
    display: flex;
    flex-direction: row;

    width: 100%;

    justify-content: space-between;

    font-size: 2rem;

    .favouriteFooterLeft {
      display: flex;
      align-items: center;
      padding: 3rem;
    }

    .favouriteFooterRight {
      display: flex;
      align-items: center;
      padding: 3rem;
    }
  }
}
