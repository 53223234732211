.inCallContainer {
  width: 100%;
  height: 100%;
  user-select: none;

  .callControl {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border-radius: 5%;

    width: 80%;
    text-align: center;
    user-select: none;

    &:hover {
      cursor: pointer;
      background-color: #222a35;
    }

    .participantCount {
      font-size: 2rem;
    }
  }

  .callControlActiveRed {
    background-color: #db220a;

    &:hover {
      background-color: #c93824;
    }
  }

  .callControlActiveYellow {
    background-color: rgba(255, 247, 0, 0.8);

    &:hover {
      background-color: rgba(255, 247, 0, 0.5);
    }
  }

  .callControlLeft {
    position: absolute;
    width: 6rem;
    top: 50%;
    left: 0;
    padding: 1rem;
    transform: translate(0%, -50%);

    z-index: 10;

    border-radius: 0 10px 10px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 3rem;
    user-select: none;

    background-color: rgba(34, 42, 53, 0.6);

    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  .callControlRight {
    position: absolute;
    width: fit-content;
    top: 50%;
    right: 0;
    padding: 1rem;
    transform: translate(0%, -50%);

    z-index: 10;

    border-radius: 10px 0px 0px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 1.5rem;

    background-color: rgba(34, 42, 53, 0.6);

    opacity: 0.8;

    // &:hover {
    //   opacity: 1;
    // }

    .callControlRightButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 1rem;
      margin: 1rem;
      border-radius: 3rem;
      min-width: 10rem;

      color: #fff;
      font-weight: 600;

      background-color: #0ebec7;
      border: 3px solid #fff;

      position: relative;
      user-select: none;

      &:hover {
        background-color: #0a99a1;
        cursor: pointer;

        & > .footerButtonNumber {
          background-color: #0ebec7;
        }
      }
    }
  }

  .callVideo {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    z-index: -99;

    video {
      background-color: #000;

      width: 100vw;
      max-height: 100vh;
    }
  }

  .callFooter {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    position: absolute;
    bottom: 0rem;

    font-size: 2rem;

    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}
