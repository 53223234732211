:root {
  //font-size: 62.5%;
  font-size: 1vw;
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
